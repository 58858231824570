<template>
  <div>
    <v-container>
      <v-layout row wrap>

        <v-flex xs12 sm12 md12 lg8 style="padding-bottom: 15px;">
          <!-- header -->
          <v-flex xs12 v-if="show_header">
            <h2>Live Train Tracking</h2>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 style="padding: 15px;">
            <!-- map -->
            <v-flex v-show="map_loader">
              <v-progress-circular
                  :size="70"
                  :width="7"
                  color="orange"
                  indeterminate
                  style="position:center; top: calc(80% - 25px); left: calc(80% - 25px);"
              ></v-progress-circular>
            </v-flex>

            <v-flex v-show="map_text" class="map-box">
              <h4>Search For valid Rake number to view its location here</h4>
            </v-flex>

            <v-flex v-show="map_check_lat_long" class="map-box">
              <h4>Invalid address to view its location here</h4>
            </v-flex>
            <v-flex class="map-box" v-show="map_visible">
              <div id="map" ref="mapComponent"></div>
            </v-flex>

          </v-flex>
        </v-flex>


        <v-flex xs12 sm12 md12 lg4 v-show="map_visible">
          <div class="information_box pa-4" transition="slide-x-transition">
            <v-layout xs12>
              <v-flex xs6>
                <div>
                  <p class="mb-0 title">Destination Station</p>
                  <p class="value-p" v-if="rake.train">{{ rake.train }}</p>
                  <p class="value-p" v-else>-</p>

                </div>
              </v-flex>
              <v-flex xs6 class="ml-2">
                <div>
                  <p class="mb-0 title">Train No. & code</p>
                  <p class="value-p" v-if="rake.trainNumberCode">{{ rake.trainNumberCode }}</p>
                  <p class="value-p" v-else>-</p>
                </div>
              </v-flex>
            </v-layout>
            <v-layout xs12>
              <v-flex xs6>
                <div>
                  <p class="mb-0 title">Rake No</p>
                  <p class="value-p" v-if="rake.rake_number">{{ rake.rake_number }}</p>
                  <p class="value-p" v-else>-</p>

                </div>
              </v-flex>
              <v-flex xs6 class="ml-2">
                <div>
                  <p class="mb-0 title">Train State</p>
                  <p class="value-p" v-if="rake.train_status==1">Running</p>
                  <p class="value-p" v-if="rake.train_status==0">Halt</p>
                </div>
              </v-flex>
            </v-layout>
            <div v-if="rake.position == 1 || rake.position == 0">
              <p class="mb-0 title">Previous Station</p>

              <p class="value-p" v-if="rake.previousStationName">
                <span v-if="rake.position == 0 || rake.position == 1"> {{ rake.previousStationName }}</span>
              </p>
              <p class="value-p" v-else>-</p>
            </div>

            <v-layout xs12>
              <v-flex xs6>
                <div>
                  <p class="mb-0 title">Schedule Time</p>
                  <p class="value-p" v-if="rake.schedule_time">{{ rake.schedule_time }}</p>
                  <p class="value-p" v-else>-</p>

                </div>
              </v-flex>
              <v-flex xs6 class="ml-2">
                <div>
                  <p class="mb-0 title">Actual Time</p>
                  <p class="value-p" v-if="rake.actual_time">{{ rake.actual_time }}</p>
                  <p class="value-p" v-else> {{ ' - '}}</p>
                </div>
              </v-flex>
            </v-layout>

            <div>
              <p class="mb-0 title">Live Train Position</p>

              <p class="value-p" v-if="rake.stationName">
                <span v-if="rake.position == 0"> {{ 'Left ' }} {{ " "}} {{ rake.stationName }}</span>
                <span v-else-if="rake.position == 1"> {{ 'Arriving ' }} {{ " "}} {{ rake.stationName }}</span>
                <span v-else-if="rake.position == 2"> {{ 'At ' }} {{ " "}} {{ rake.stationName }}</span>
              </p>
              <p class="value-p" v-else>-</p>
            </div>

            <div>
              <p class="mb-0 title">Current Address</p>
              <p class="value-p" v-if="address!=null">{{ address }}</p>
              <p class="value-p" v-else>-</p>

            </div>
            <div>
              <h6 class="mb-0 title">Last Updated at:</h6>
              <p style="font-size: 14px;" v-if="rake.ispast">
                {{ moment(rake.created_at).format("MMM Do YYYY HH:mm:ss A") }}
                {{"("}} {{
                  moment
                      .duration(
                          moment(rake.tracking_time, "YYYY/MM/DD HH:mm:ss").diff(
                              moment().format("YYYY/MM/DD HH:mm:ss")
                          )
                      )
                      .humanize()
                }} {{ " ago" }} {{")"}}
              </p>
              <p style="font-size: 14px;" v-else>
                {{ moment(rake.created_at).format("MMM Do YYYY HH:mm A") }} {{"("}}{{
                  moment
                      .duration(
                          moment(rake.tracking_time, "YYYY/MM/DD HH:mm:ss").diff(
                              moment().format("YYYY/MM/DD HH:mm:ss")
                          )
                      )
                      .humanize()
                }} {{ " ago" }}{{")"}}
              </p>
            </div>
            <v-spacer></v-spacer>
            <div class="form">
              <v-btn class="white secondary--text mt-15" @click="close()">Close</v-btn>
            </div>
          </div>
        </v-flex>

      </v-layout>
    </v-container>
    <v-snackbar :color="snackBar.color" :top="snackBar.top" :bottom="snackBar.bottom" :left="snackBar.left"
                :right="snackBar.right" v-model="snackBar.show">
      <v-btn dark text @click.native="snackBar.show = false">
        {{ snackBar.message }}
        <v-spacer></v-spacer>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

  </div>
</template>

<script>
export default {
  name: "Detail",
  data() {
    return {
      rake_number: "",
      is_loading: false,
      map_visible: false,
      map_text: false,
      drawer: null,
      items: [
        {title: 'Home', icon: 'mdi-view-dashboard'},
        {title: 'About', icon: 'mdi-forum'},
      ],
      snackBar: {
        show: false,
        color: "",
        message: "",
        top: true,
        right: true,
        bottom: false,
        left: false,
      },
      rake: {},
      address: null,
      show_header: false,
      default_center: {
        latitude: 51.958,
        longitude: 9.797,
      },
      item_data: [],
      center: [51.958, 9.797],
      map1: '',
      map_check_lat_long: false,
      map_loader: false,
    }
  },
  methods: {
    close() {
      this.map_visible = false;
      this.map_text = true;
      this.$router.push({name: 'search'})
    },
    searchForRake() {
      this.is_loading = true;
      this.map_loader = true;
      axios
          .post(`${process.env.VUE_APP_API_URL}/api/v1/rake/search`, {rakenumber: this.$route.params.id})
          .then(response => {
            if (response.data.status == 1) {
              this.map_loader = true;
              this.map_text = false;
              this.is_loading = false;
              this.map_visible = true;
              this.show_header = true;
              this.rake = response.data.rake;
              this.map1.off();
              this.map1.remove();
              this.setupLeafletMap(response.data);
            } else {
              this.map_loader = false;
              this.snackBar.show = true;
              this.map_visible = false;
              this.map_text = true;
              this.snackBar.message = response.data.message;
            }
          })
          .catch(error => {
            this.map_loader = false;
            console.log(error);
          })
          .finally(() => {
            this.map_loader = false;
            this.is_loading = false;
          });
    },

    setupLeafletMap(data) {
      let longitude, latitude;

      let mapOptions = {
        zoom: 12
      }
      this.map1 = new L.map('map', {mapOptions, renderer: L.canvas()});


      // let myIcon = L.icon(customIcon);
      var icon = L.icon({
        iconUrl: require('../../assets/images/train_icon.png'),
        iconRetinaUrl: require('../../assets/images/train_icon.png'),
        iconSize: [30, 30],
        // popupAnchor: [-3, -15]
      });

      let iconOptions = {
        title: "company name",
        draggable: true,
        icon: icon
      }

      if (data == undefined) {
        // this.map_visible = false;
        longitude = this.default_center.longitude;
        latitude = this.default_center.latitude;
      } else {
        if (data.rake.latitude == '0' || data.rake.longitude == '0') {
          this.map_visible = false;
          this.map_check_lat_long = true;
        } else {
          this.map_visible = true;
          latitude = data.rake.latitude
          longitude = data.rake.longitude
          this.map1.setView([latitude, longitude], 15);
          this.reverse_geocode(data.rake);
        }

      }

      var layer = new L.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 17,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      });

      this.map1.addLayer(layer);

      var marker = new L.Marker([latitude, longitude], iconOptions)

      marker.addTo(this.map1);


      // if (data != undefined){
      //   marker.bindPopup(data.rake.rake_number).openPopup();
      // }

      // let popup = L.popup().setLatLng([51.958, 9.797] ).setContent("<p>new popup</br> more complicated</p>").openOn(map);

    },
    reverse_geocode(rake) {

      var licence_key = localStorage.getItem("map_access_token");
      var latitude = rake.latitude;
      var longitude = rake.longitude;

      const url = 'https://nominatim.openstreetmap.org/reverse?lat=' + latitude + '&lon=' + longitude +
          '&zoom=18&format=json';
      axios
          .get(url)
          .then(response => {
            if (response) {
              if (response.status == 200) {
                setTimeout(() => {
                  if (this.map1) {
                    this.map1.invalidateSize();
                    // Workaround to re-open popups at their correct position.
                    this.map1.eachLayer((layer) => {
                      if (layer instanceof L.Marker) {
                        layer.openPopup();
                      }
                    });
                  }
                }, 0);
                this.address = response.data.display_name;
              }
            } else {
              this.snackBar.show = true;
              this.snackBar.message = response.data.message;
            }
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.is_loading = false;
          });
    }
  },
  mounted() {
    this.setupLeafletMap();
    this.searchForRake();
  }
}
</script>

<style scoped>
.map-box {
  background-color: #f3f3f3;
  height: 65vh;
  width: 100%;
  display: flex;
  /* align-items: baseline; */
  text-align: center;
  justify-content: space-around;
  align-content: space-around;
  flex-wrap: wrap;
}

#map {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 65vh;
}

.information_box {
  background: #2D314F;
  border-radius: 24px;
  height: 100%;
  width: 100%;
  color: white;
  padding: 9% !important;
}

.value-p {
  background: rgba(255, 255, 255, 0.08);
  border: 2px solid rgba(236, 234, 245, 0.12);
  border-radius: 8px;
  padding: 9px;
  font-size: 13px;
}

.title {
  font-size: 13px !important;
}

.form .v-btn {
  width: 100%;
}
</style>
