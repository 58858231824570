<template>
  <div>
    <v-navigation-drawer
        v-model="drawer"
        id="core-navigation-drawer"
        fixed
        app
        :mini-variant="mini"
        mini-variant-width="80"
        width="260" v-bind="$attrs"
        class="accent-4"
        dark
    >
      <v-list-item>
        <v-list-item-content class="white--text">
          <v-list-item-title v-if="!mini">
            <img
                src="@/assets/images/company_logo.png"
                style="width: 91px"
                class="company_logo"
            />
          </v-list-item-title>

          <v-list-item-title v-else>
            <img
                src="@/assets/images/company_logo.png"
                style="width: 35px"
                class="company_logo"
            />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list nav>
        <v-list-item-group v-model="group" class="nav-children">
          <v-list-item
              v-for="item in headers"
              :key="item.name"
              link
              :to="item.url !== '' ? item.url : '#'"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main style="padding-bottom: 0px !important; padding-top: 0px">
      <v-app-bar flat app>
        <v-btn class="mr-3" text fab small @click="((drawer = !drawer) || (mini = !mini))">
          <v-icon v-if="value">
            mdi-menu
          </v-icon>

          <v-icon v-else>
            mdi-menu
          </v-icon>
        </v-btn>

        <v-spacer></v-spacer>

        <v-spacer></v-spacer>
        <v-chip label color="orange" outline text-color="white">
          Trial Expiring on 31st March 2023
        </v-chip>
        <div>

          <v-form @submit.prevent="logout()" ref="form">
            <v-btn type="submit" :loading="logout_loader" text
            ><span>Logout</span></v-btn>
            |
          </v-form>
        </div>


        <div>
          <v-menu
              offset-y
              left
              nudge-bottom="14"
              min-width="230"
              content-class="user-profile-menu-content"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                  bottom
                  color="success"
                  overlap
                  offset-x="12"
                  offset-y="12"
                  class="ms-4"
                  dot
              >
                <v-avatar size="40px" v-bind="attrs" v-on="on">
                  <v-img :src="require('@/assets/images/default.png')"></v-img>
                </v-avatar>
              </v-badge>
            </template>
            <v-list>
              <div class="pb-3 pt-2">
                <v-badge
                    bottom
                    color="success"
                    overlap
                    offset-x="12"
                    offset-y="12"
                    class="ms-4"
                    dot
                >
                  <v-avatar size="40px">
                    <v-img :src="require('@/assets/images/default.png')"></v-img>
                  </v-avatar>
                </v-badge>
                <div
                    class="d-inline-flex flex-column justify-center ms-3"
                    style="vertical-align: middle"
                >
                <span class="text--primary font-weight-semibold mb-n1">
                  {{ username }}
                </span>
                </div>
              </div>
            </v-list>
          </v-menu>
        </div>
      </v-app-bar>
    </v-main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: false,
      logout_loader: false,
      drawer: true,
      group: null,
      mini: false,
      headers: [
        // selection-marker
        {
          icon: "mdi-selection-search",
          title: "Rake Search",
          name: "rake_search",
          url: "/rake-search",
        },
        {
          icon: "mdi-notebook",
          title: "Rake Master",
          name: "rake_master",
          url: "/rake-list",
        },
        {
          icon: "mdi-selection-off",
          name: "Non Functioning Rake list",
          title: "Non-functioning-rake-list",
          url: "/non-functioning-rake-list",
        },
      ],
      expandOnHover: false,
      value: false,
    };
  },
  computed: {
    // eslint-disable-next-line vue/no-dupe-keys
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit('SET_DRAWER', val)
      },
    },

  },
  watch: {
  },
  methods: {
    getInitials: function () {
      let return_string =
          this.$store.getters.get_user.first_name.toUpperCase() +
          " " +
          this.$store.getters.get_user.last_name.toUpperCase();
      this.username = return_string;
    },

    logout() {
      this.logout_loader = true;
      axios
          .post(`${process.env.VUE_APP_API_URL}/api/v1/rake/logout`)
          .then((response) => {
            if (response.status == 1) {
              localStorage.clear();
              this.$store.dispatch("removeAccessToken");
              this.logout_loader = false;
              this.$router.push({ name: 'login'});
            } else {
              localStorage.clear();
              this.$store.dispatch("removeAccessToken");
              this.logout_loader = false;
              this.$router.push({ name: 'login'});
            }
          })
          .catch((error) => {
            this.$router.replace({name: "login"});
            localStorage.clear();
            this.$store.dispatch("removeAccessToken");
            this.logout_loader = false;
          });
    },
  },
  mounted() {
    this.getInitials();
  },
};
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}

.v-navigation-drawer .v-list-item--active {
  box-shadow: inset 0px 0 3px 0 #ff5252 !important;
  background: linear-gradient(90deg, #ff5e5c 0%, #ff9233 100%) !important;
}
</style>


