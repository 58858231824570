import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        global_loader: false,
        access_token: '',
        permissions: [],
        office_location: {},
        user: {
            roles: []
        },
        redirect_url: '',
        drawer: '',
    },
    getters: {
        get_access_token: (state) => state.access_token,
        get_permissions: (state) => state.permissions,
        get_office_location: (state) => state.office_location,
        get_user: (state) => state.user,
        get_redirect_url: (state) => state.redirect_url,
        show_global_loader: (state) => state.global_loader,
    },
    mutations: {
        storeAccessToken(state, access_token) {
            state.access_token = access_token;
        },
        removeAccessToken(state) {
            state.access_token = '';
        },
        storePermissions(state, permissions) {
            state.permissions = permissions;
        },
        removePermissions(state) {
            state.permissions = [];
        },
        storeOfficeLocation(state, location_object) {
            state.office_location = location_object;
        },
        removeOfficeLocation(state) {
            state.office_location = {};
        },
        storeUser(state, user) {
            state.user = user;
        },
        removeUser(state) {
            state.user = {
                roles: []
            };
        },
        storeRedirectUrl(state, redirect_url) {
            state.redirect_url = redirect_url;
        },
        removeRedirectUrl(state) {
            state.redirect_url = '';
        },
        updateLoaderState(state, status) {
            state.global_loader = status
        },
        SET_DRAWER(state, payload) {
            state.drawer = payload
        },
    },
    actions: {
        startLoader({commit}) {
            return new Promise((resolve) => {
                commit('updateLoaderState', true);
                resolve();
            });
        },
        stopLoader({commit}) {
            return new Promise((resolve) => {
                commit('updateLoaderState', false);
                resolve();
            });
        },
        storeAccessToken({commit}, access_token) {
            return new Promise((resolve, reject) => {
                commit('storeAccessToken', access_token);
                resolve();
            });
        },
        removeAccessToken({commit}) {
            return new Promise((resolve, reject) => {
                commit('removeAccessToken');
                resolve();
            });
        },
        storePermissions({commit}, permissions) {
            return new Promise((resolve, reject) => {
                let permissions_arr = JSON.parse(permissions);
                commit('storePermissions', permissions_arr);
                resolve();
            });
        },
        removePermissions({commit}) {
            return new Promise((resolve, reject) => {
                commit('removePermissions');
                resolve();
            });
        },
        storeOfficeLocation({commit}, location_object) {
            return new Promise((resolve, reject) => {
                commit('storeOfficeLocation', location_object);
                resolve();
            });
        },
        removeOfficeLocation({commit}) {
            return new Promise((resolve, reject) => {
                commit('removeOfficeLocation');
                resolve();
            });
        },
        storeUser({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('storeUser', user);
                resolve();
            });
        },
        removeUser({commit}) {
            return new Promise((resolve, reject) => {

                commit('removeUser');
                resolve();
            });
        },
        storeRedirectUrl({commit}, redirect_url) {
            return new Promise((resolve, reject) => {
                commit('storeRedirectUrl', redirect_url);
                resolve();
            });
        },

        removeRedirectUrl({commit}) {
            return new Promise((resolve, reject) => {
                commit('removeRedirectUrl');
                resolve();
            });
        },
    }
})
