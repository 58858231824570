<template>
  <v-container fluid grid-list-md class="loginscreen">

    <v-layout row wrap>
      <v-flex xs12 sm12 lg4></v-flex>
      <v-flex xs12 sm8 md4 lg4 class="login_container">
        <v-card class="pa-5 justify-center" style="border-style: solid; border-width: 0px; border-radius: 8px;">
          <v-card-title class="pb-0">
            <v-flex xs12 class="text-center">
              <img src="@/assets/images/company_logo.png" style='width: 47%'>
            </v-flex>
          </v-card-title>

          <h3 class="primary--text text--darken-2 text-center">Welcome to Yatri</h3>
          <v-spacer></v-spacer>
          <v-card-actions class="mt-10">
            <v-form @submit.prevent="validateBeforeSubmit" width="100%" class="login_form" data-vv-scope='login_form'
                    autocomplete="off">

              <v-text-field v-model="email" label="Enter Email-ID" name="email" autocomplete=off
                            v-validate="'required|email'" outlined
                            :error-messages="errors.collect('login_form.email')">
              </v-text-field>

              <v-btn type="submit" class="primary white--text" :loading="is_loading" :disabled="is_loading">Send OTP
              </v-btn>
            </v-form>

          </v-card-actions>
          <br><br>
        </v-card>
        <br><br>
      </v-flex>
      <v-flex xs12 sm12 lg4></v-flex>

    </v-layout>
   <br><br><br>
    <v-snackbar :color="snackBar.color" :top="snackBar.top" :bottom="snackBar.bottom" :left="snackBar.left"
                :right="snackBar.right" v-model="snackBar.show">
      <v-btn dark text @click.native="snackBar.show = false">
        {{ snackBar.message }}
        <v-spacer></v-spacer>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  created() {
  },
  methods: {
    login() {
      this.is_loading = true;
      let user_data = [this.email];
      var key = 'a2qT8wXZkL8YTsXT';
      var CryptoJS = require("crypto-js");
      // var AES = require("crypto-js/aes");
      var CryptoJSAesJson = {
        stringify: function (cipherParams) {
          var j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
          if (cipherParams.iv) j.iv = cipherParams.iv.toString();
          if (cipherParams.salt) j.s = cipherParams.salt.toString();
          return JSON.stringify(j).replace(/\s/g, '');
        },
        parse: function (jsonStr) {
          var j = JSON.parse(jsonStr);
          var cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});
          if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
          if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
          return cipherParams;
        }
      }
      var data = CryptoJS.AES.encrypt(JSON.stringify(user_data), key, {format: CryptoJSAesJson}).toString();
      let headers = {
        'app-type': 'yatri-web',
        'api-key': process.env.VUE_APP_API_KEY
      };

      axios
          .post(`${process.env.VUE_APP_API_URL}/api/v1/rake/login`, {data: data}, {headers})
          .then(response => {
            if (response.data.status == 1) {
              this.$router.push({name: "otp", params: {email: this.email}});

            } else if(response.data.status == 0) {
              this.snackBar.show = true;
              this.snackBar.message = response.data.message;
            }
            else if(response.data.status == 403){
              this.snackBar.show = true;
              this.snackBar.message = response.data.message;
            }
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.is_loading = false;
          });
    },

    validateBeforeSubmit() {
      this.$validator.validate().then(result => {
        if (result) {
          this.login();
        }
        return false;
      });
    },
  },

  data() {
    return {
      is_loading: false,
      e1: true,
      email: "",
      snackBar: {
        show: false,
        color: "",
        message: "",
        top: true,
        right: false,
        bottom: false,
        left: false,
      },
    };
  },
  mounted() {
    if (this.$route.query.error != null) {
      this.snackBar.show = true;
      this.snackBar.message = this.$route.query.error;//"Session Expired";
    }
  }
};
</script>

<style scoped>
.login_form,
.v-btn {
  width: 100%;
}
.body{
  background-color: green;
}
.logo {
  width: 40%;
  margin-top: 8%;
}

.login_container {
  /*background-color: #ffffff;*/
  /*box-shadow: 1px 3px 9px 4px #e4e5e6;*/
  margin-top: 100px;
  border-collapse: collapse;
}

.loginscreen {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  margin: auto;
  background-color: #ECEFF2;
}

</style>
