<template>
  <div>
    <v-container fluid grid-list-md class="padding_10">

      <v-flex lg2></v-flex>

      <v-card
          class="mx-auto"
          tile>
        <v-app id="inspire">
          <v-card-title>
            <v-layout row wrap>
              <v-flex lg6 md12 sm12>
                <v-text-field
                    v-model="filters.rakeNumber"
                    append-icon="mdi-magnify"
                    label="Enter rake number to search. Eg: 2201"
                    clearable
                    single-line
                    hide-details solo
                    v-on:keyup.enter="getRakeList()"
                    :disabled="searchingNumber == true"
                ></v-text-field>
              </v-flex>
              <v-flex lg2 md12 sm12></v-flex>
              <v-flex lg4 md12 sm12>
                <v-autocomplete
                    label="Search For Carshed"
                    v-model="filters.Carshed"
                    :items="base_location"
                    item-text="carshed"
                    item-value="carshed"
                    chips
                    clearable
                    name="name"
                    @select="getRakeList()"
                    solo
                    :disabled="searchingItem == true"
                ></v-autocomplete>
              </v-flex>

            </v-layout>
          </v-card-title>
          <v-data-table :headers="headers"
                        :loading="loader" :items="list"
                        item-key="id"
                        class="elevation-1"
                        :options.sync="pagination"
                        loading-text="Loading... Please wait"
                        :server-items-length="total_items">

            <template #item.rake_number="{ item }">
              <router-link
                  :to="{ name: 'rake-details', params: { id: item.rake_number } }">
                {{ item.rake_number }}
              </router-link>
            </template>

            <template #item.base_location="{ item }">
              {{ item.carshed }}
            </template>

            <template #item.rake_address="{ item }" >
              {{ item.rake_address }}
            </template>


            <template #item.train_status="{ item }">
              <span v-if="item.train_status == '1'">{{"Running"}}</span>
              <span v-else-if="item.train_status == '0'">{{"Stopped"}}</span>
              <span v-else>{{" - "}}</span>
            </template>

            <template #item.tracking_time="{ item }">
              <span v-if="item.tracking_time != '-'">
                {{
                  moment
                      .duration(
                          moment(item.tracking_time, "YYYY/MM/DD HH:mm:ss").diff(
                              moment().format("YYYY/MM/DD HH:mm:ss")
                          )
                      )
                      .humanize()
                }}
                {{ " ago" }}
              </span>
              <v-span v-else>{{ " - "}}</v-span>
            </template>

            <template #item.gps_status="{ item }">
              <span v-if="item.gps_status == 1">{{"Enable"}}</span>
              <span v-else-if="item.gps_status == 0">{{"Disable"}}</span>
              <span v-else>{{" - "}}</span>
              <!--              <span v-if="item.gps_status">{{-->
              <!--                item.gps_status-->
              <!--              }}</span>-->
            </template>

            <template v-slot:item.action="{ item }">

              <span v-if="item.tracking_time != '-'">
                <v-icon color="red"
                        v-if="moment.duration(moment(moment().format('YYYY/MM/DD HH:mm:ss')).diff(moment(item.tracking_time, 'YYYY/MM/DD HH:mm:ss'))).asMinutes() > 15">
                  mdi-checkbox-blank-circle</v-icon>
                <v-icon color="green" v-else>mdi-checkbox-blank-circle</v-icon>
              </span>
              <span v-else
              ><v-icon color="red">mdi-checkbox-blank-circle</v-icon></span
              >
            </template>


          </v-data-table>
        </v-app>
      </v-card>

      <v-snackbar v-model="snackbar.display" :color="snackbar.color" :top="snackbar.top" :bottom="snackbar.bottom"
                  :left="snackbar.left" :right="snackbar.right" :timeout="snackbar.timeout">
        {{ snackbar.message }}
        <v-btn
            text
            dark
            @click="snackbar.display = false"
        >
          <v-icon
              right
              color="white"
          >
            close
          </v-icon>
        </v-btn>
      </v-snackbar>
    </v-container>

  </div>
</template>

<script>
export default {
  name: "non-functioning-rake-list",
  watch: {
    pagination: {
      handler() {
        this.getRakeList();
      }
    },
    'filters.rakeNumber': function (val) {
      if (val == null || val == undefined || val == '') {
        this.loader = true;
        this.getRakeList();
      }
    },
    "filters.Carshed": function (val) {
      if (val != null) {
        this.isLoading = true;
        this.getRakeList();
      } else if (val == null || val == undefined || val == "") {
        this.isLoading = true;
        this.getRakeList();
      }
    },
    list: function (val){
      if(val.length != 0) {
        this.list.forEach((item) => {
          if((item.latitude != null || item.latitude != '') && (item.longitude != null || item.longitude != ''))
          {
            if(item.rake_address == '' && item.latitude != '0' && item.longitude != '0'){
              axios
                  .get('https://nominatim.openstreetmap.org/reverse?lat=' + item.latitude
                      + '&lon='
                      + item.longitude + '&zoom=14&format=json')
                  .then((response) => {
                    item.rake_address = response.data.display_name;
                    return item;
                  })
                  .catch((error) => {
                  });
            }else{
              item.rake_address = " - ";
              return item;
            }
          }
        })
      }
    },
  },
  data() {
    return {
      rake_number: "",
      is_loading: false,
      search: '',
      options: {},

      sortDesc: false,
      pagination: {descending: true, rowsPerPage: 10,},
      headers: [
        {text: 'Number', value: 'rake_number', filterable: true},
        {text: 'Base Location', value: 'base_location', sortable: false},
        {text: "Current Location", value: "rake_address", sortable: false},
        {text: 'Train Status', value: 'train_status', sortable: false},
        {text: 'Tracking time', value: 'tracking_time', sortable: false},
        {text: 'GPS Status', value: 'gps_status', sortable: false},
        {text: 'Action', value: 'action'},

      ],
      list: [],
      total_items: 0,
      loader: false,
      snackbar: {
        message: '',
        display: false,
        color: 'black',
        top: true,
        right: true,
        bottom: false,
        left: false,
        timeout: 2000
      },
      filters: {
        rakeNumber: '',
        Carshed: 'ALL',
      },
      search_name: "",
      base_location: [],
      searchingItem: false,
      searchingNumber: false,
    }
  },
  methods: {
    getRakeList() {
      this.list = [];
      let headers = {
        'app-type': 'yatri-web',
        'api-key': process.env.VUE_APP_API_KEY
      };
      this.pagination.filters = Object.assign({}, this.filters);
      this.loader = true;
      this.searchingItem = true;
      this.searchingNumber = true;
      axios
          .get(`${process.env.VUE_APP_API_URL}/api/v1/rake/fetch-non-functioning-list`, {
            params:
            this.pagination, headers
          }).then(response => {
        if (response.data.status == 1) {
          this.list = response.data.data.data;
          this.total_items = response.data.data.total;
          this.loader = false;
          this.searchingItem = false;
          this.searchingNumber = false;

        } else if (response.data.status == 0) {
          this.loader = false;
          this.snackbar.message = response.data.message;
          this.snackbar.display = true;
          this.searchingItem = false;
          this.searchingNumber = false;

        }
      }).catch((error) => {
        console.log(error);
        this.loader = false;
        this.snackbar.message = "Something went wrong";
        this.snackbar.display = true;
      });
    },
    getBaseLocationList() {
      this.loader = true;
      axios.get(`${process.env.VUE_APP_API_URL}/api/v1/rake/get-base-location`).then(response => {
        if (response.data.status == 1) {
          this.base_location = response.data.data;
          this.base_location.push({carshed : 'ALL'});

          return this.base_location.sort((a, b) => {
            if (a.carshed < b.carshed) return -1;
            if (a.carshed > b.carshed) return 1;
            return 0;
          });

        } else if (response.data.status == 0) {

          this.snackbar.message = response.data.message;
          this.snackbar.display = true;
        }
      }).catch((error) => {
        this.snackbar.message = "Something went wrong";
        this.snackbar.display = true;
      });
    },

  },
  mounted() {
    this.getBaseLocationList();
  },
  computed: {
  //   filteredItems(){
  //   return this.list.filter((i) => {
  //     if ((i.latitude != null || i.latitude != '')&& (i.longitude != null || i.longitude != '')) {
  //       i.rake_address = "ashwini";
  //
  //       return i;
  //     } else {
  //       i.rake_address = " - ";
  //       return i;
  //     }
  //   });
  //
  // },
  }
}
</script>

<style scoped>

</style>
