import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: require('@/views/Authentication/Login').default,
    meta: { forGuests: true }
  }, 
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: require('@/views/Authentication/Login').default,
  //   meta: { forGuests: true }
  // },
  {
    path: '/otp',
    name: 'otp',
    component: require('@/views/Authentication/Otp').default,
    meta: { forGuests: true }
  }, 
  {
    path: '/rake-search',
    name: 'search',
    component: require('@/views/LandingPage/Search').default,
    meta: { forUsers: true }
  },
  {
    path: '/rake-list',
    name: 'list',
    component: require('@/views/LandingPage/Index').default,
    meta: { forUsers: true }
  },
  {
    path: '/rake-details/:id',
    name: 'rake-details',
    component: require('@/views/LandingPage/Detail').default,
    meta: { forUsers: true }
  },
  {
    path: '/non-functioning-rake-list',
    name: 'non-functioning-rake-list',
    component: require('@/views/LandingPage/non-functioning-rake-list').default,
    meta: { forUsers: true }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
