<template>
  <div id="app">
    <v-app id="inspire">
      <navigation v-if="get_access_token"></navigation>
      <v-container
          fluid
          grid-list-md
          px-0>
        <v-main>
          <router-view></router-view>
          <!--        <customfooter />-->
          <v-footer style="position:fixed; bottom:0; width:100%; height:40px;">
<!--            <v-flex xs2></v-flex>-->
            <v-flex xs12 md12 sm12 lg10 style="text-align: center;">
              <span class="footer"> Design and Developed by - CDP Yatri Pvt. Ltd.</span>
            </v-flex>
<!--            <v-flex xs3></v-flex>-->
          </v-footer>
        </v-main>
      </v-container>

    </v-app>
  </div>
</template>

<script>
import navigation from "@/components/TheNavigation.vue";
import customfooter from "@/components/TheFooter.vue";
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(["get_access_token", "get_user"]),
  },

  name: "App",
  components: {
    navigation,
    customfooter,
  },
  data: () => ({
    authenticated: false,
  }),
  mounted() {
    if (!localStorage.getItem("access_token") == null) {
      this.authenticated = true;
    }
  },
};
</script>
<style lang="scss">
.v-application--wrap{
  background-color: #ECEFF2;
}
</style>
