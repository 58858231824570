<template>
  <div>
    <v-container fluid grid-list-md class="padding_10">
      <v-layout row wrap>
        <v-flex xs12 sm12 md12 lg12 v-if="show_header">
          <h2>Live Train Tracking</h2>
        </v-flex>

        <v-flex xs12 sm12 md12 lg12 class="mt-4">
          <v-form @submit.prevent="validateBeforeSubmit" width="100%" class="search_form" data-vv-scope='search_form'
                  autocomplete="off">
            <v-layout row wrap>
              <v-flex xs12 sm12 md8 lg4>
                <v-text-field v-model="rake_number" type='number' dense label="Enter rake number to search. Eg: 2201" name="Rake Number"
                              prepend-inner-icon="mdi-magnify" outlined
                              :error-messages="errors.collect('search_form.Rake Number')">
                </v-text-field>
              </v-flex>

<!--              <v-flex xs12 sm12 md12 lg1 style="margin-top: 6px; padding-left: 30px;">-->
<!--                <span>OR</span>-->
<!--              </v-flex>-->
<!--              <v-flex xs12 sm12 md8 lg4>-->
<!--                <v-autocomplete-->
<!--                    label="Enter Train Number"-->
<!--                    v-model="train_number"-->
<!--                    :items="trainList"-->
<!--                    :item-text="getItemText"-->
<!--                    item-value="number"-->
<!--                    :loading="loader"-->
<!--                    chips clearable  hide-details-->
<!--                    hide-selected-->
<!--                    name="name" dense outlined-->
<!--                    :search-input.sync="search_train"-->
<!--                    style="padding-top: 0px; margin-top: 0px;"-->
<!--                ></v-autocomplete>-->
<!--              </v-flex>-->

              <v-flex xs12 sm12 md4 lg3 pl-4>
                <v-btn type="submit" class="secondary white--text" :loading="is_loading" :disabled="is_loading">
                  Search
                </v-btn>
              </v-flex>
            </v-layout>
          </v-form>
        </v-flex>
      </v-layout>

      <v-layout row wrap style="position: absolute; width: 100%; height: 100%;">
        <v-flex xs12 sm12 md12 lg8 style="padding-bottom: 15px;">
          <!-- header -->
          <v-flex v-show="map_text" class="map-box" style="padding: 15px;">
            <h4>Search Rake number to view its location here</h4>
          </v-flex>

          <v-flex class="map-box" v-show="map_visible" >
            <div id="map" ref="mapComponent"></div>
          </v-flex>
        </v-flex>


        <v-flex xs12 sm12 md12 lg4 v-show="map_visible">
          <div class="information_box pa-4" transition="slide-x-transition">
            <v-layout xs12>
              <v-flex xs6>
                <div>
                  <p class="mb-0 title">Destination Station</p>
                  <p class="value-p" v-if="rake.train">{{ rake.train }}</p>
                  <p class="value-p" v-else>-</p>

                </div>
              </v-flex>
              <v-flex xs6 class="ml-2">
                <div>
                  <p class="mb-0 title">Train No. & code</p>
                  <p class="value-p" v-if="rake.trainNumberCode">{{ rake.trainNumberCode }}</p>
                  <p class="value-p" v-else>-</p>
                </div>
              </v-flex>
            </v-layout>
            <v-layout xs12>
              <v-flex xs6>
                <div>
                  <p class="mb-0 title">Rake No</p>
                  <p class="value-p" v-if="rake.rake_number">{{ rake.rake_number }}</p>
                  <p class="value-p" v-else>-</p>

                </div>
              </v-flex>
              <v-flex xs6 class="ml-2">
                <div>
                  <p class="mb-0 title">Train State</p>
                  <p class="value-p" v-if="rake.train_status==1">Running</p>
                  <p class="value-p" v-if="rake.train_status==0">Halt</p>
                </div>
              </v-flex>
            </v-layout>

            <div v-if="rake.position == 1 || rake.position == 0">
              <p class="mb-0 title">Previous Station</p>

              <p class="value-p" v-if="rake.previousStationName">
                <span v-if="rake.position == 0 || rake.position == 1"> {{ rake.previousStationName }}</span>

              </p>
              <p class="value-p" v-else>-</p>
            </div>


<!--            <v-layout xs12>-->
<!--              <v-flex xs6>-->
<!--                <div>-->
<!--                  <p class="mb-0 title">Schedule Time</p>-->
<!--                  <p class="value-p" v-if="rake.schedule_time">{{ rake.schedule_time }}</p>-->
<!--                  <p class="value-p" v-else>-</p>-->

<!--                </div>-->
<!--              </v-flex>-->
<!--              <v-flex xs6 class="ml-2">-->
<!--                <div>-->
<!--                  <p class="mb-0 title">Actual Time</p>-->
<!--                  <p class="value-p" v-if="rake.actual_time">{{ rake.actual_time }}</p>-->
<!--                  <p class="value-p" v-else> {{ ' - '}}</p>-->
<!--                </div>-->
<!--              </v-flex>-->
<!--            </v-layout>-->

            <div>
              <p class="mb-0 title">Live Train Position</p>

              <p class="value-p" v-if="rake.stationName">
                <span v-if="rake.position == 0"> {{ 'Left ' }} {{ " "}} {{ rake.stationName }}</span>
                <span v-else-if="rake.position == 1"> {{ 'Arriving ' }} {{ " "}} {{ rake.stationName }}</span>
                <span v-else-if="rake.position == 2"> {{ 'At ' }} {{ " "}} {{ rake.stationName }}</span>
                </p>
              <p class="value-p" v-else>-</p>
            </div>

            <div>
              <p class="mb-0 title">Current Address</p>
              <p class="value-p" v-if="address!=null">{{ address }}</p>
              <p class="value-p" v-else>-</p>

            </div>
            <div>
              <h6 class="mb-0 title">Last Updated at:</h6>
              <p style="font-size: 14px;" v-if="rake.ispast">
                {{ moment(rake.created_at).format("MMM Do YYYY HH:mm:ss A") }}
                {{"("}}{{
                  moment
                      .duration(
                          moment(rake.tracking_time, "YYYY/MM/DD HH:mm:ss").diff(
                              moment().format("YYYY/MM/DD HH:mm:ss")
                          )
                      )
                      .humanize()
                }} {{ " ago" }}{{")"}}
              </p>
              <p style="font-size: 14px;" v-else>{{ moment(rake.created_at).format("MMM Do YYYY HH:mm A") }}
                {{"("}}{{
                  moment
                      .duration(
                          moment(rake.tracking_time, "YYYY/MM/DD HH:mm:ss").diff(
                              moment().format("YYYY/MM/DD HH:mm:ss")
                          )
                      )
                      .humanize()
                }} {{ " ago" }}{{")"}}
              </p>
            </div>
            <v-spacer></v-spacer>
            <div class="form">
              <v-btn class="white secondary--text mt-15" @click="close()">Close</v-btn>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
    <v-snackbar :color="snackBar.color" :top="snackBar.top" :bottom="snackBar.bottom" :left="snackBar.left"
                :right="snackBar.right" v-model="snackBar.show">
      <v-btn dark text @click.native="snackBar.show = false">
        {{ snackBar.message }}
        <v-spacer></v-spacer>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

  </div>
</template>

<script>
export default {
  data: () => ({
    rake_number: "",
    is_loading: false,
    map_visible: false,
    map_text: true,
    drawer: null,
    items: [
      {title: 'Home', icon: 'mdi-view-dashboard'},
      {title: 'About', icon: 'mdi-forum'},
    ],
    snackBar: {
      show: false,
      color: "",
      message: "",
      top: true,
      right: true,
      bottom: false,
      left: false,
    },
    rake: {},
    address: null,
    show_header: false,
    default_center: {
      latitude: 51.958,
      longitude: 9.797,
    },
    center: [51.958, 9.797],
    map1 : '',
    loader: false,
    trainList: [],
    train_number: '',
    search_train: '',
  }),
  watch:{
    rake_number: function (val){
      if (val == '' ||  val== undefined){
        this.map_visible = false;
        this.map_text = true;
      }
    },
    search_train: function(val){
      if (val != null && val.length >= 3) {
        this.isLoading = true;
        this.getTrainList();
      }

    },
  },
  methods: {
    close() {
      this.map_visible = false;
      this.map_text = true;
      this.rake = [];
      this.rake_number = '';
      this.address = '';
      // this.$router.push({name: 'list'})
    },
    setupLeafletMap(data) {

      let longitude, latitude;

      let mapOptions = {
        zoom: 12
      }
      this.map1 = new L.map('map', {mapOptions, renderer: L.canvas()});

      // let myIcon = L.icon(customIcon);
      var icon = L.icon({
        iconUrl: require('../../assets/images/train_icon.png'),
        iconRetinaUrl: require('../../assets/images/train_icon.png'),
        iconSize: [30, 30],
        // popupAnchor: [-3, -15]
      });

      let iconOptions = {
        title: "company name",
        draggable: true,
        icon: icon
      }

      if (data == undefined) {
        this.map_visible = false;
        longitude = this.default_center.longitude;
        latitude = this.default_center.latitude;
      } else {
        this.map_visible = true;
        latitude = data.rake.latitude
        longitude = data.rake.longitude
        this.map1.setView([latitude, longitude], 15);
        this.reverse_geocode(data.rake);
      }

      var layer = new L.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 17,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      });

      this.map1.addLayer(layer);

      var marker = new L.Marker([latitude, longitude], iconOptions)

      marker.addTo(this.map1);

      if (data != undefined){
        marker.bindPopup(data.rake.rake_number).openPopup();
      }

      // let popup = L.popup().setLatLng([51.958, 9.797] ).setContent("<p>new popup</br> more complicated</p>").openOn(map);

    },
    validateBeforeSubmit() {
      this.$validator.validate().then(result => {
        if (result) {
          this.search();
        }
        return false;
      });
    },

    search() {
      this.is_loading = true;
      this.map_visible = false;
      this.map_text = true;
      this.rake = [];
      this.address = '';
      axios
          .post(`${process.env.VUE_APP_API_URL}/api/v1/rake/search`, {rakenumber: this.rake_number, trainNumber:
            this.train_number})
          .then(response => {
            if (response.data.status == 1) {
              this.is_loading = false;
              this.map_text = false;
              this.map_visible = true;
              this.show_header = true;
              this.rake = response.data.rake;
              this.map1.off();
              this.map1.remove();
              this.setupLeafletMap(response.data);
            } else {
              this.snackBar.show = true;
              this.map_visible = false;
              this.map_text = true;
              this.snackBar.message = response.data.message;
            }
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.is_loading = false;
          });
    },

    reverse_geocode(rake) {

      var licence_key = localStorage.getItem("map_access_token");
      var latitude = rake.latitude;
      var longitude = rake.longitude;

      const url = 'https://nominatim.openstreetmap.org/reverse?lat=' + latitude + '&lon=' + longitude +
          '&zoom=19&format=json';
      axios
          .get(url)
          .then(response => {
            if (response) {
              if (response.status == 200) {
                setTimeout(() => {
                  if (this.map1) {
                    this.map1.invalidateSize();
                    // Workaround to re-open popups at their correct position.
                    this.map1.eachLayer((layer) => {
                      if (layer instanceof L.Marker) {
                        layer.openPopup();
                      }
                    });
                  }
                }, 0);
                this.address = response.data.display_name;
              }
            } else {
              this.snackBar.show = true;
              this.snackBar.message = response.data.message;
            }
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.is_loading = false;
          });
    },
    getTrainList(){
      this.loader = true;
      axios.get(`${process.env.VUE_APP_API_URL}/api/v1/rake/get-train-list`).then(response => {
        if (response.data.status == 1) {
          this.trainList = response.data.data;
          this.loader = false;
        }else if(response.data.status == 0){
          this.loader = false;
          this.snackBar.show = true;
          this.snackBar.message = response.data.message;
        }
      }).catch((error) => {
        this.loader = false;
        this.snackBar.show = true;
        this.snackBar.message = "Something went wrong";
      });
    },
    getItemText(item) {
      // ${item.short_code}
      return `${item.number} ${" - "} ${item.short_code}`;

    }
  },

  mounted() {
    this.setupLeafletMap();
  }

}
</script>

<style scoped>
.map-box {
  background-color: #f3f3f3;
  height: 65vh;
  width: 100%;
  display: flex;
  /* align-items: baseline; */
  text-align: center;
  justify-content: space-around;
  align-content: space-around;
  flex-wrap: wrap;
}

#map {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 60vh;
  z-index: 3;
}

.information_box {
  background: #2D314F;
  border-radius: 24px;
  height: 100%;
  width: 100%;
  color: white;
  padding: 9% !important;
}

.value-p {
  background: rgba(255, 255, 255, 0.08);
  border: 2px solid rgba(236, 234, 245, 0.12);
  border-radius: 8px;
  padding: 9px;
  font-size: 13px;
}

.title {
  font-size: 13px !important;
}

.form .v-btn {
  width: 100%;
}
</style>
