<template>
<div name="snackbars">
    <v-snackbar v-model="snackbar" :color="color" :timeout="timeout" :top="'top'">
        {{ text }}
        <template v-slot:action="{ attrs }">
            <v-btn dark text v-bind="attrs" @click="snackbar = false">
                Close
            </v-btn>
        </template>
    </v-snackbar>
</div>
</template>

  
<script>
export default {
    props: {
        snackbar: {
            type: Boolean,
            required: true,
        },
        color: {
            type: String,
            required: false,
            default: "success",
        },
        timeout: {
            type: Number,
            required: false,
            default: 3000,
        },
        text: {
            type: String,
            required: true,
        },
    },
};
</script>
