import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import vuetify from './plugins/vuetify'
import axios from 'axios';
import VeeValidate from 'vee-validate'
import "./plugins/base";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import moment from 'moment';
import VueAnalytics from 'vue-analytics';


import 'moment-timezone';

require('@/assets/css/style.css');

Vue.config.productionTip = false

Vue.prototype.moment = moment
window.axios = axios;
Vue.use(VeeValidate)


// Configuration VueAnalytics
Vue.use(VueAnalytics, {
    id: 'UA-249682523-1',
    router,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.forGuests)) {

        if (localStorage.getItem("access_token") != null) {
            next({name: 'list'});
        }
        next();
    } else if (to.matched.some(record => record.meta.forUsers)) {
        if (localStorage.getItem("access_token") === null) {
            next({name: "login"});
        } else {
            if (!store.getters.get_access_token) {
                store.dispatch("storeAccessToken", localStorage.getItem("access_token"));
                store.dispatch("storeUser", JSON.parse(localStorage.getItem("auth_user")));
                axios.defaults.headers.common["Authorization"] = "Bearer " + store.getters.get_access_token;
            }

            // console.warn(localStorage.getItem("access_token"));
            // if(localStorage.getItem("access_token")){
            //     // alert("access tokem");
            //     next({ name: "list" });
            // }
            next();
        }
    } else next();
});


window.axios.interceptors.response.use(null, function (error) {
    switch (error.response.status) {
        case 401:
            localStorage.removeItem('access_token');
            localStorage.removeItem('expiration');
            localStorage.removeItem('permissions')
            localStorage.removeItem('auth_user')
            store.dispatch('removeAccessToken');
            router.push({name: 'login', query: {error: 'Session Expired'}});
            break;
        case 403:
            localStorage.removeItem('access_token');
            localStorage.removeItem('expiration');
            localStorage.removeItem('permissions')
            localStorage.removeItem('auth_user')
            store.dispatch('removeAccessToken');
            router.push({name: 'login', query: {error: 'Access restricted on this device!'}});
            break;
        case 404:
            //
            break;
        case 429:
            //
            break;
        default:
            break;
    }
    return Promise.reject(error);
});
Vue.mixin({
    methods: {
        momentdate(date) {
            return moment(date).format('h:mm A');
        },
        momentDateTime(date) {
            return moment(date).format('MMM Do YY h:mm A');
        }
    }
});

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
