<template>
<div class="loginscreen">
    <v-layout row wrap style="padding: 10px;">
          <v-flex xs4 sm12 md12 lg4>

          </v-flex>
            <v-flex xs12 sm12 md8 lg4 class="login_container">
                <v-card class="pa-5 justify-center">
                    <v-card-title class="pb-0">
                        <v-flex xs12 class="text-center">
                            <img src="@/assets/images/company_logo.png" style='width: 47%'>
                        </v-flex>
                    </v-card-title>

                    <h3 class="primary--text text--darken-2 text-center">Welcome to Yatri</h3>
                    <v-card-actions class="mt-10">
                        <v-form @submit.prevent="validateBeforeSubmit" width="100%" class="login_form" data-vv-scope='login_form' autocomplete="off">
                            <v-layout>
                                <v-flex class="xs9">
                                    <h5>Email - <strong>{{this.$route.params.email}}</strong></h5>
                                </v-flex>
                                <v-flex style="text-align-last: end;">
                                    <a>
                                        <router-link to="/login" active-class="active">Change</router-link>
                                    </a>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs12 style="text-align: end;">
                                    <v-text-field v-model="otp" label="Enter OTP" name="otp" autocomplete=off v-validate="'required'" outlined :error-messages="errors.collect('login_form.otp')">
                                    </v-text-field>
                                    <div class="text-xs-right" v-if="timerCount == 0">Did not recieve OTP?
                                        <a class="secondary--text" @click="sendOTP">
                                            <u>Resend OTP</u>
                                        </a>
                                    </div>
                                    <div v-else class="text-xs-right">
                                        You can resend OTP in {{ timerCount }} seconds
                                    </div>
                                </v-flex>
                            </v-layout><br>
                            <v-btn type="submit" class="primary white--text" style="margin-top: 23px;" :loading="is_loading" :disabled="is_loading">Login</v-btn>
                        </v-form>

                    </v-card-actions>
                </v-card>
            </v-flex>
            <v-flex xs4 sm12 md12 lg4>

            </v-flex>
    </v-layout>
    <v-snackbar :color="snackBar.color" :top="snackBar.top" :bottom="snackBar.bottom" :left="snackBar.left" :right="snackBar.right" v-model="snackBar.show">
        <v-btn dark text @click.native="snackBar.show = false">
            {{ snackBar.message }}
            <v-spacer></v-spacer>
            <v-icon>mdi-close</v-icon>
        </v-btn>
    </v-snackbar>
</div>
</template>

<script>
import axios from 'axios';
export default {
    watch: {

        timerCount: {
            handler(value) {

                if (value > 0) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                }

            },
            immediate: true // This ensures the watcher is triggered upon creation
        }

    },
    created() {},
    methods: {
        login() {
            this.is_loading = true;
            let headers = {
                'app-type': 'yatri-web',
                'api-key': process.env.VUE_APP_API_KEY
            };

            axios
                .post(`${process.env.VUE_APP_API_URL}/api/v1/rake/verifyOtp`, { otp: this.otp, email: this.$route.params.email }, { headers })
                .then(response => {
                    if (response.data.status == 1) {
                        this.$store.dispatch('storeAccessToken', response.data.access_token);
                        this.$store.dispatch('storeUser', response.data.user)
                            .then(() => {
                                localStorage.setItem("access_token", response.data.access_token);
                                localStorage.setItem("expiration", response.data.expiration);
                                localStorage.setItem("auth_user", JSON.stringify(response.data.user));
                                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.get_access_token;
                                this.$router.push({ name: "search" });
                            });

                    } else {
                        this.snackBar.show = true;
                        this.snackBar.message = response.data.message;
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.is_loading = false;
                });
        },

        validateBeforeSubmit() {
            this.$validator.validate().then(result => {
                if (result) {
                    this.login();
                }
                return false;
            });
        },

        sendOTP() {
            axios
                .post(`${process.env.VUE_APP_API_URL}/api/v1/rake/resend-otp`, { email: this.$route.params.email })
                .then(response => {
                    if (response.data.status == 1) {
                        this.timerCount = 15;
                    } else {
                        this.snackBar.show = true;
                        this.snackBar.message = response.data.message;
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.is_loading = false;
                });
        }

    },

    data() {
        return {
            is_loading: false,
            e1: true,
            otp: "",
            snackBar: {
                show: false,
                color: "",
                message: "",
                top: true,
                right: false,
                bottom: false,
                left: false,
            },
            timerCount: 15

        };

    },
    mounted() {
        if (!this.$route.params.email) {
            this.$router.push({ name: "login" });
        }
    }
};
</script>

<style scoped>
.login_form,
.v-btn {
    width: 100%;
}

.logo {
    width: 40%;
    margin-top: 8%;
}

.login_container {
  background-color: #ffffff;
  box-shadow: 1px 3px 9px 4px #e4e5e6;
  border-style: solid;
  border-width: 0px;
  border-radius: 8px;
  margin-top: 100px;
  border-collapse: collapse;
}

.loginscreen {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    margin: auto;
    background-color: #ECEFF2;
}
</style>
